// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-daniel-husar-gatsby-theme-spring-src-pages-404-tsx": () => import("./../node_modules/@daniel.husar/gatsby-theme-spring/src/pages/404.tsx" /* webpackChunkName: "component---node-modules-daniel-husar-gatsby-theme-spring-src-pages-404-tsx" */),
  "component---node-modules-daniel-husar-gatsby-theme-spring-src-templates-blog-tsx": () => import("./../node_modules/@daniel.husar/gatsby-theme-spring/src/templates/blog.tsx" /* webpackChunkName: "component---node-modules-daniel-husar-gatsby-theme-spring-src-templates-blog-tsx" */),
  "component---node-modules-daniel-husar-gatsby-theme-spring-src-templates-post-tsx": () => import("./../node_modules/@daniel.husar/gatsby-theme-spring/src/templates/post.tsx" /* webpackChunkName: "component---node-modules-daniel-husar-gatsby-theme-spring-src-templates-post-tsx" */),
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-cv-tsx": () => import("./../src/pages/cv.tsx" /* webpackChunkName: "component---src-pages-cv-tsx" */),
  "component---src-pages-gabriela-tsx": () => import("./../src/pages/gabriela.tsx" /* webpackChunkName: "component---src-pages-gabriela-tsx" */)
}

